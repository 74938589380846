<template>
  <div :class="['filter-area', filterAreaClass]">
    <div class="filter-area-list">
      <Listbox
        v-model="selectedItemIdLocal"
        :tabindex="0"
        :options="entities"
        :option-label="itemLabel"
        :filter="true"
        :empty-message="noFilterResult"
        :empty-filter-message="noFilterResult"
        @change="onChange"
      >
        <template #header>
          <label class="listbox-label">
            <strong>{{ labelText }}</strong>
          </label>
        </template>
        <template #option="slotProps">
          <slot
            name="option-content"
            :slot-props="slotProps"
          />
        </template>
      </Listbox>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Listbox from 'primevue/listbox';
import resourceHelper from '@/Utils/resourceHelper';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';

const props = defineProps<{
  modelValue: string | null,
  entities: any,
  itemType: string,
  filterAreaClass: string,
  itemLabel: any
}>();

const emit = defineEmits<{(e: 'change', selectedItemId: string | null): void}>();
const noFilterResult = resourceHelper.getString('NoFilterResult');
const labelText = props.itemType === FilterAreaType.user ?
  resourceHelper.getString('FilterUsersLabel') : resourceHelper.getString('FilterGroupsLabel');
const selectedItemIdLocal = ref(props.modelValue);
const lastSelectedItem = ref(props.modelValue);

function onChange(event) {
  // make sure that at least one items remains selected as Listbox allows unselection
  if (!event.value) {
    selectedItemIdLocal.value = lastSelectedItem.value;
    return;
  }
  lastSelectedItem.value = event.value;
  emit('change', event.value);
}

</script>

<style>
.listbox-label {
  padding-top: 1rem;
  padding-left: 1rem;
}
</style>