<template>
  <div class="col-md-4 col-xs-12 text-right">
    <label
      v-if="!isLabelHidden"
      class="control-label"
      :for="id"
    >{{ labelText }}</label>
  </div>
  <div class="col-md-4 col-xs-12">
    <InputText
      :id="id"
      v-model="filterCriteriaValue"
      class="form-control filter-input"
      :name="id"
      aria-label="FilterListAriaLabel"
      :disabled="!hasDataModels"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import InputText from 'primevue/inputtext';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{
  id: string;
  filterDataModels: { id: string; isMultipleFilteringMode: boolean; primaryFilteredField: any; secondaryFilteredField?: any }[];
  filteredIds: string[];
  labelTextKey: string;
  filterCriteriaValue: string;
  isLabelHidden?: boolean;
}>();

const emit = defineEmits<{(e: 'filterIdsUpdated', filteredIds: string[], newFilterCriteriaValue: string): void }>();

const filterCriteriaValue = ref(props.filterCriteriaValue);
const labelText = resourceHelper.getString(props.labelTextKey);
const filteredIds = ref(props.filteredIds);
const hasDataModels = computed(() => props.filterDataModels.length > 0);

watch(() => props.filterCriteriaValue, newValue => {
  filterCriteriaValue.value = newValue;
});

watch(() => props.filteredIds, newValue => {
  filteredIds.value = newValue;
});

// Watch for changes in filterCriteriaValue
watch(filterCriteriaValue, newValue => {
  filteredIds.value.length = 0;
  if (!newValue || newValue.length <= 0) {
    props.filterDataModels.forEach(model => filteredIds.value.push(model.id));
  } else {
    props.filterDataModels.forEach(model => {
      if (model.isMultipleFilteringMode) {
        if (isRelatedItem(newValue, model.primaryFilteredField) ||
            isRelatedItem(newValue, model.secondaryFilteredField)) {
          filteredIds.value.push(model.id);
        }
      } else {
        if (isRelatedItem(newValue, model.primaryFilteredField)) {
          filteredIds.value.push(model.id);
        }
      }
    });
  }
  emit('filterIdsUpdated', filteredIds.value, newValue);
});

// Helper function to check if criteria matches the filter object
function isRelatedItem(criteria: string, filterObject: any): boolean {
  const lowerCaseCriteria = criteria.toLowerCase();
  if (Array.isArray(filterObject)) {
    const relationCount = filterObject.filter((o: string) => o.toLowerCase().startsWith(lowerCaseCriteria)).length;
    const lowerRowItems = filterObject.join(' ').toLowerCase();
    if (lowerRowItems.startsWith(lowerCaseCriteria)) {
      return relationCount + 1 > 0;
    }
    return relationCount > 0;
  } else {
    return filterObject.toLowerCase().includes(lowerCaseCriteria);
  }
}
</script>

<style scoped lang="scss">
@import "sass/site/_colours.scss";
.filter-input {
  background-color: $background-light-grey;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid $input-border-color;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: initial;

  &:enabled:focus {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
    border-radius: initial;
  }
}
</style>